<template>
  <div class="imprintParent">
    <a
      class="topRightCloseButton"
      v-if="!$store.getters.isAuthenticated"
      v-on:click="goToLogin()"
      >X</a
    >
    <div class="imprintWrapper">
      <h1>{{ $t("imprint.imprint") }}</h1>
      <div v-html="$t('imprint.text')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imprint",
  data() {
    return {};
  },
  methods: {
    goToLogin() {
      this.$router.push(this.$i18nRoute({ name: "Login" }));
    },
  },
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.imprintParent {
  height: 100%;
  overflow: auto;
}
.imprintWrapper {
  padding: 2em;
}
</style>